// Section.js

import React from 'react';

const Section = () => {
  return (
    <section className="u-clearfix u-container-align-center-xl u-palette-4-base u-section-1" id="sec-3e54">
      <img src="images/cinedeafs.jpg" className="u-align-left u-expanded-width u-image u-image-1" alt="Cinedeafs" />
      <div className="u-align-left u-container-style u-group u-radius-50 u-shape-round u-similar-fill u-white u-group-1">
        <div className="u-container-layout u-padding-12 u-container-layout-1">
          <h1 className="u-align-center u-text u-text-palette-4-base u-text-1">Menu - Deaf's</h1>
          <p className="u-align-center u-text u-text-2">Um mundo de entretenimento acessível e diversificado, feito especialmente para quem curte filmes e séries com legendas e dublagem 🎬📺</p>
          <a href="#form" className="u-active-palette-4-dark-2 u-align-center u-border-none u-btn u-btn-round u-button-style u-hover-palette-4-dark-2 u-palette-4-base u-radius-50 u-btn-2">Formulário</a>
        </div>
      </div>
    </section>
  );
}

export default Section;