// src/components/FormSection.js
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import "./assets/App.css";

const FormSection = () => {
  const [nome, setNome] = useState("");
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org/?format=json");
        if (response.ok) {
          const data = await response.json();
          const ip = data.ip || "Não disponível";
          setIpAddress(ip);

          const registros = JSON.parse(localStorage.getItem("registros")) || [];
          const registro = registros.find(
            (registro) => registro.ipAddress === ip,
          );
          console.log("Registro encontrado:", registro);

          if (registro) {
            const deviceType = getDeviceType();
            const message =
              deviceType === "mobile"
                ? "Parece que você já preencheu o formulário. Por favor, verifique se já está nos canais do Telegram no seu dispositivo móvel."
                : "Parece que você já preencheu o formulário. Por favor, verifique se já está nos canais do Telegram no seu desktop.";

            Swal.fire({
              icon: "info",
              title: "Opa!",
              text: message,
            });
          }
        } else {
          throw new Error("Erro ao obter o endereço IP");
        }
      } catch (error) {
        setIpAddress("Não disponível");
      }
    };

    fetchIpAddress();
  }, []);

  const getDeviceType = () => {
    const userAgent = navigator.userAgent;
    if (/Mobi|Android|iPhone/i.test(userAgent)) {
      return "mobile";
    }
    return "desktop";
  };

  const validarNome = (nome) => {
    const nomeRegex = /^[a-zA-Zà-úÀ-Ú\s]+$/;
    return nomeRegex.test(nome);
  };

  const verificarSeJaEntrou = () => {
    const registros = JSON.parse(localStorage.getItem("registros")) || [];

    const registro = registros.find(
      (registro) => registro.ipAddress === ipAddress,
    );

    return registro ? registro.nome === nome : false;
  };

  const verificarNomePorIp = () => {
    const registros = JSON.parse(localStorage.getItem("registros")) || [];

    const registro = registros.find(
      (registro) => registro.ipAddress === ipAddress,
    );

    return registro ? registro.nome !== nome : false;
  };

  const verificarVpn = async () => {
    try {
      const response = await fetch(
        `https://ipinfo.io/${ipAddress}?token=${process.env.REACT_APP_IPINFO_TOKEN}`,
      );
      if (response.ok) {
        const data = await response.json();
        return (
          data.org &&
          (data.org.toLowerCase().includes("vpn") ||
            data.org.toLowerCase().includes("proxy"))
        );
      } else {
        throw new Error("Erro ao verificar IP");
      }
    } catch (error) {
      console.error("Erro ao verificar VPN:", error);
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validarNome(nome)) {
      Swal.fire({
        icon: "error",
        title: "Nome inválido!",
        text: "Por favor, digite um nome válido com caracteres latinos.",
      });
      return;
    }

    if (await verificarVpn()) {
      Swal.fire({
        icon: "error",
        title: "VPN detectada",
        text: "O uso de VPN não é permitido.",
      });
      return;
    }

    if (verificarNomePorIp()) {
      Swal.fire({
        icon: "error",
        title: "Desculpe.",
        text: "Não é permitido criar um nome com o mesmo IP.",
      });
      return;
    }

    if (verificarSeJaEntrou()) {
      const pageUrl = process.env.REACT_APP_PAGE_URL;
      Swal.fire({
        icon: "info",
        title: "Já entrou",
        text: "Você já preencheu o formulário. Por favor, verifique se já está nos canais do Telegram.",
      }).then(() => {
        if (pageUrl) {
          window.location.href = pageUrl;
        } else {
          console.error("REACT_APP_PAGE_URL não está definido.");
        }
      });
      return;
    }

    try {
      const formData = {
        name: nome,
        ipAddress: ipAddress,
        redirecionar: process.env.REACT_APP_LINK_URL,
        lang: "pt-BR",
      };

      const response = await fetch("https://formspree.io/f/xqazkedn", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Erro ao enviar dados");
      }

      const registros = JSON.parse(localStorage.getItem("registros")) || [];
      registros.push({ nome: nome, ipAddress: ipAddress });
      localStorage.setItem("registros", JSON.stringify(registros));

      setNome("");

      Swal.fire({
        icon: "success",
        title: "Perfeito!",
        text: "Seu formulário foi enviado. Obrigado :)",
      }).then(() => {
        const redirectUrl = process.env.REACT_APP_LINK_URL;
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          console.error("REACT_APP_LINK_URL não está definido.");
        }
      });
    } catch (error) {
      console.error("Erro ao enviar dados:", error);
      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: "Houve um problema ao redirecionar. Por favor, entre em contato com o desenvolvedor.",
      });
    }
  };

  return (
    <section
      className="u-clearfix u-container-align-center u-section-8"
      id="form"
    >
      <form onSubmit={handleSubmit}>
        <h1>Formulário</h1>
        <p>
          <label htmlFor="name">Seu nome</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder=""
            required
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
        </p>
        {/* Inputs ocultos */}
        <input
          type="hidden"
          name="verificado"
          value="https://menu.fsdeafs.com.br"
        />
        <input type="hidden" name="lang" value="pt-BR" />
        <p>
          <button type="submit" className="txtButton">
            Acessar
          </button>
        </p>
      </form>
    </section>
  );
};

export default FormSection;
