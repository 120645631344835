// Section3.js

import React from "react";

const Section3 = () => {
  return (
    <section className="u-align-center u-clearfix u-section-4" id="sec-cf78">
      <div className="u-clearfix u-sheet u-valign-middle-md u-valign-middle-sm u-valign-middle-xl u-valign-middle-xs u-sheet-1">
        <div className="u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-align-left u-container-align-left u-container-style u-layout-cell u-right-cell u-size-30 u-layout-cell-1">
                <div className="u-container-layout u-valign-middle u-container-layout-1">
                  <h5 className="u-align-left u-text u-text-1">
                    Quem é Diego Melo?
                  </h5>
                  <p className="u-align-left u-text u-text-3">
                    Ele é surdo, profissional de TI, desenvolvedor full-stack e
                    web com experiência em desenvolvimento iOS/Android e
                    engenheiro de software. Com 14 anos de experiência em
                    informática, sempre busca novas experiências em diversas
                    áreas. Começou com manutenção, sistemas de informação e
                    servidores. Ele é um nerd/geek de computador contínuo e ama
                    tudo relacionado a computadores, software e novas
                    tecnologias. Além disso, ele é o fundador (dono) do{" "}
                    <strong>CineDeaf's</strong> no grupo do Telegram.
                  </p>
                  <a
                    href="https://links.tifodao.com"
                    className="u-active-palette-4-dark-2 u-align-left u-border-none u-btn u-btn-round u-button-style u-hover-palette-4-dark-2 u-palette-4-base u-radius-50 u-btn-2"
                  >
                    Contato
                  </a>
                </div>
              </div>
              <div className="u-container-style u-layout-cell u-left-cell u-size-30 u-layout-cell-2">
                <div className="u-container-layout u-valign-middle-md u-valign-middle-sm u-valign-middle-xl u-valign-middle-xs u-container-layout-2">
                  <div className="u-shape u-shape-svg u-text-palette-4-base u-shape-1">
                    <svg
                      className="u-svg-link"
                      preserveAspectRatio="none"
                      viewBox="0 0 160 160"
                      style={{}}
                    >
                      <use xlinkHref="#svg-a0bf"></use>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      xmlSpace="preserve"
                      className="u-svg-content"
                      viewBox="0 0 160 160"
                      x="0px"
                      y="0px"
                      id="svg-a0bf"
                      style={{ enableBackground: "new 0 0 160 160" }}
                    >
                      <path
                        d="M80,30c27.6,0,50,22.4,50,50s-22.4,50-50,50s-50-22.4-50-50S52.4,30,80,30 M80,0C35.8,0,0,35.8,0,80s35.8,80,80,80
                        s80-35.8,80-80S124.2,0,80,0L80,0z"
                      ></path>
                    </svg>
                  </div>
                  <div
                    className="u-border-17 u-border-palette-4-base u-image u-image-circle u-image-1"
                    data-image-width="720"
                    data-image-height="1080"
                  ></div>
                  <div
                    alt=""
                    className="u-align-left u-border-11 u-border-white u-image u-image-circle u-image-2"
                    data-image-width="1280"
                    data-image-height="853"
                  ></div>
                  <div className="u-palette-4-base u-shape u-shape-circle u-shape-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section3;
