// Footer.js

import React from 'react';

const Footer = () => {
  return (
    <footer className="u-clearfix u-footer u-grey-80" id="sec-9fee">
      <div className="u-clearfix u-sheet u-sheet-1">
        <a href="#inicio" className="u-image u-logo u-image-1" data-image-width="80" data-image-height="40">
          <img src="images/favicon.ico" className="u-logo-image u-logo-image-1" alt="Logo" />
        </a>
        <div className="u-border-1 u-border-white u-expanded-width u-line u-line-horizontal u-opacity u-opacity-50 u-line-1"></div>
        <p className="u-align-center u-text u-text-1">
          <script type="text/javascript">
            {`document.write(new Date().getFullYear());`}
          </script>
          &nbsp;&copy;&nbsp;Todos os direitos reservados.
        </p>
      </div>
    </footer>
  );
};

export default Footer;