// Section2.js

import React from 'react';

const Section2 = () => {
  return (
    <section className="u-align-center u-clearfix u-palette-4-base u-section-2" id="carousel_d7e9">
      <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
        <div className="u-expanded-width u-list u-list-1">
          <div className="u-repeater u-repeater-1">
            <div className="u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-3">
              <div className="u-container-layout u-similar-container u-valign-top u-container-layout-5">
                <div className="u-container-style u-group u-shape-rectangle u-group-3">
                  <div className="u-container-layout">
                    <div className="u-palette-4-base u-preserve-proportions u-shape u-shape-circle u-shape-3"></div>
                    <span className="u-file-icon u-icon u-text-palette-4-dark-3 u-icon-3">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                        <path d="M248 43.4C130.6 43.4 35.4 138.6 35.4 256S130.6 468.6 248 468.6 460.6 373.4 460.6 256 365.4 43.4 248 43.4zm-97.4 132.9c0-53.7 43.7-97.4 97.4-97.4s97.4 43.7 97.4 97.4v26.6c0 5-3.9 8.9-8.9 8.9h-17.7c-5 0-8.9-3.9-8.9-8.9v-26.6c0-82.1-124-82.1-124 0v26.6c0 5-3.9 8.9-8.9 8.9h-17.7c-5 0-8.9-3.9-8.9-8.9v-26.6zM389.7 380c0 9.7-8 17.7-17.7 17.7H124c-9.7 0-17.7-8-17.7-17.7V238.3c0-9.7 8-17.7 17.7-17.7h248c9.7 0 17.7 8 17.7 17.7V380zm-248-137.3v132.9c0 2.5-1.9 4.4-4.4 4.4h-8.9c-2.5 0-4.4-1.9-4.4-4.4V242.7c0-2.5 1.9-4.4 4.4-4.4h8.9c2.5 0 4.4 1.9 4.4 4.4zm141.7 48.7c0 13-7.2 24.4-17.7 30.4v31.6c0 5-3.9 8.9-8.9 8.9h-17.7c-5 0-8.9-3.9-8.9-8.9v-31.6c-10.5-6.1-17.7-17.4-17.7-30.4 0-19.7 15.8-35.4 35.4-35.4s35.5 15.8 35.5 35.4zM248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 478.3C121 486.3 17.7 383 17.7 256S121 25.7 248 25.7 478.3 129 478.3 256 375 486.3 248 486.3z"/>
                      </svg>
                    </span>
                  </div>
                </div>
                <h4 className="u-align-center u-text u-text-5">SSL</h4>
                <p className="u-align-center u-text u-text-grey-50 u-text-6">O Transport Layer Security, assim como o seu antecessor Secure Sockets Layer, é um protocolo de segurança projetado para fornecer segurança nas comunicações sobre uma rede de computadores.</p>
              </div>
            </div>

            <div className="u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-4">
              <div className="u-container-layout u-similar-container u-valign-top u-container-layout-7">
                <div className="u-container-style u-group u-shape-rectangle u-group-4">
                  <div className="u-container-layout">
                    <div className="u-palette-4-base u-preserve-proportions u-shape u-shape-circle u-shape-4"></div>
                    <span className="u-file-icon u-icon u-text-palette-4-dark-3 u-icon-4">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                        <path d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3V245.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V389.2C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112h32c24 0 46.2 7.5 64.4 20.3zM448 416V394.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176h32c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2V416c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3V261.7c-10 11.3-16 26.1-16 42.3zm144-42.3v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2V448c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V405.2c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112h32c61.9 0 112 50.1 112 112z"/>
                      </svg>
                    </span>
                  </div>
                </div>
                <h4 className="u-align-center u-text u-text-7">Team</h4>
                <p className="u-align-center u-text u-text-grey-50 u-text-8">Equipe dedicada a buscar, compartilhar links e fazer o upload de filmes e séries para surdos. Nossos admins estão sempre prontos para encontrar o que você precisa e garantir que tudo esteja pronto para você assistir. Simples assim!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section2;